<template>
  <transition
    enter-active-class="animated fadeIn faster"
    leave-active-class="animated fadeOut faster"
    mode="out-in"
  >
    <component :is="property.component" v-bind="{ property }" />
  </transition>
</template>

<script>
export default {
  name: 'SimilarListingsFiltersDropdown',
  components: {
    distance: () => import('./SimilarListingsDistanceFilter'),
    mileage: () => import('./SimilarListingsMileageFilter'),
    year: () => import('./SimilarListingsYearFilter'),
    keyword: () => import('./SimilarListingsKeywordFilter'),
    checkbox: () => import('./SimilarListingsCheckboxFilter')
  },
  props: {
    property: {
      type: Object,
      required: false,
      default: () => null
    }
  }
}
</script>
